import React from 'react';

import Hero from '../components/Hero';
import PageStyles from '../styles/PageStyles';

export default function TietosuojaselostePage() {
  return (
    <PageStyles>
      <Hero>
        <span>KIITOS</span>
        <br /> VIESTISTÄ!
      </Hero>
    </PageStyles>
  );
}
